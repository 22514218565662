// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputPassword extends BaseInput {
	private visibility: boolean
	
	constructor(key: string, label: string) {
		super()
		this.Key        = key
		this.Label      = label
		this.Visibility = false
		this.setParamsType('password')
		this.setParamsState(super.resolveInputState)
	}

	public get Visibility() {
		return this.visibility
	}

	public set Visibility(value) {
		this.visibility = value
	}
}