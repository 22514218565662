// Constants
import { Component } from '@/Constants/Global/Component'

// Class Export
export class BaseElement {
	private key: string
	private label: string
	private params: any

	constructor() {
		this.params = {}
	}

	public get Key() {
		return this.key
	}

	public set Key(value) {
		this.key = value
	}

	public get Label() {
		return this.label
	}

	public set Label(value) {
		this.label = value
	}

	public get Params() {
		return this.params
	}

	public isParamsOptional() {
		this.params.optional = true
		return this
	}

	public setParamsState(func: Function) {
		this.params.state = func
		return this
	}

	public setParamsPlaceHolder(placeholder: string) {
		this.params.placeholder = placeholder
		return this
	}

	public setParamsReadOnly(readonly: Component.Actions | Component.Actions[]) {
    	if (Array.isArray(readonly)) {
        	this.params.readOnlyAt = readonly
    	}
		else {
        	this.params.readOnlyAt = [readonly]
    	}
    	return this
	}

	public setParamsValidation(fn: (value: any) => boolean) {
		this.params.validation = fn
		return this
	}

	public setParamsVisible(visible: Component.Actions) {
		this.params.visible = visible
		return this
	}
}