// Helpers
import { BaseElement } from '@/Helpers/Components/DataForm/BaseElement'

// Class Export
export class InputSelect extends BaseElement {
	private options: Array<any>
	private selected: any
	private type: string

	constructor(key: string, label: string, options: Array<any> = []) {
		super()
		this.Key      = key
		this.Label    = label
		this.options  = options
		this.type     = 'InputSelect'
		this.selected = null
		this.setParamsState(this.resolveInputState)
	}

	public get Options() {
		return this.options
	}

	public set Options(options) {
		this.options = options
	}

	public get Selected() {
		return this.selected
	}

	public set Selected(value) {
		this.selected = value
	}

	public get Type() {
		return this.type
	}

	public resolveInputState(self: InputSelect) {
		if (self?.selected === null) return null
		return true
	}
}