// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputButton extends BaseInput {
	private data: { value: string }

	constructor(key: string, label: string) {
		super()
		this.Key   = key
		this.Label = label
		this.Type  = 'InputButton'
		this.data  = { value: '' }
		this.setParamsState(this.resolveInputState)
		this.setParamsType('text')
	}

	public get Data() {
		return this.data
	}

	public set Data(value) {
		this.data = value
	}

	public resolveInputState(self: any) {
		if (self.data.value === '') return null
		return true
	}

	public setParamsIcon(icon: (string | [string, string])) {
		this.Params.button = { content: 'icon', icon }
		return this
	}

	public setParamsRequires(field: string, friendly: string, values: any) {
		this.Params.requires = { field, friendly, values }
		return this
	}

	public setParamsType(type: string) {
		this.Params.type = type
		return this
	}
}