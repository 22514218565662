// Helpers
import { BaseElement } from '@/Helpers/Components/DataForm/BaseElement'

// Class Export
export class BaseInput extends BaseElement {
	private events: { [key: string]: Function }
	private type: string
	private value: string

	constructor() {
		super()
		this.events = {}
		this.type = 'BaseInput'
		this.value = ''
	}

	public get Events() {
		return this.events
	}

	public get Type() {
		return this.type
	}

	public set Type(value) {
		this.type = value
	}

	public get Value() {
		return this.value
	}

	public set Value(value) {
		this.value = value
	}

	public addEvent(eventName: string, func: Function) {
		this.events[eventName] = func
		return this
	}

	public resolveInputState(self: any) {
		if (self?.value === '') return null
		return true
	}

	public setParamsFormatter(formatter: Function) {
		this.Params.formatter = formatter
		return this
	}

	public setParamsType(type: string): void {
		this.Params.type = type
	}
}