// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputEmail extends BaseInput {
	constructor(key: string, label: string) {
		super()
		this.Key = key
		this.Label = label
		this.setParamsState(this.resolveInputState)
		this.setParamsType('email')
	}

	public resolveInputState(self: any) {
		let aux = self.value.trim()
		if (aux === '') return null
		return /^[a-z0-9]+(.+)@[a-z0-9]+\.[a-z0-9]+$/i.test(aux)
	}
}