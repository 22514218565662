// Helpers
import { BaseElement } from '@/Helpers/Components/DataForm/BaseElement'

// Class Export
export class InputDatePicker extends BaseElement {
	private readonly type = 'InputDatePicker'
	private value: string | null

	constructor(key: string, label: string) {
		super()
		this.Key = key
		this.Label = label
		this.value = null
	}

	public get Type() {
		return this.type
	}

	public get Value() {
		return this.value
	}

	public set Value(value) {
		this.value = value
	}

	public clearDate() {
		this.value = null
		return this
	}

	public setDate(date: string) {
		const dateOnly = date.split('T')[0]
		this.value = dateOnly
		return this
	}
}
