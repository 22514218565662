// Classes
import { RutTools } from '@/Classes/Static/RutTools'

// Constants
import { Component } from '@/Constants/Global/Component'

// Helpers
import { InputButton }   from '@/Helpers/Components/DataForm/InputButton'
import { InputEmail }    from '@/Helpers/Components/DataForm/InputEmail'
import { InputPassword } from '@/Helpers/Components/DataForm/InputPassword'
import { InputPhone }    from '@/Helpers/Components/DataForm/InputPhone'
import { InputSelect }   from '@/Helpers/Components/DataForm/InputSelect'
import { InputText }     from '@/Helpers/Components/DataForm/InputText'

// Input's Validator
const rutStateValidator = (self: any) => {
	if (self.value === '') return null
	return RutTools.validator(self.value)
}

// Input's Validator
const rutKeyUpEvent = (self: any) => {
	if (self.value === '') return
	self.value = RutTools.formatter(self.value)
}

// Form Export
export default {
	params: {
		currentTab: 'clients',
		numColumns: 2,
	},
	tabs: [
		{
			header: {
				key: 'clients',
				text: { onInsert: 'Nuevo Contacto', onUpdate: 'Actualizar Usuario Cliente' }
			},
			columns: [
				new InputText('rut', 'Rut')
					.addEvent('keyup', rutKeyUpEvent)
					.setParamsPlaceHolder('Ej: 11111111-1')
					.setParamsState(rutStateValidator)
				,
				new InputEmail('email', 'Correo')
					.setParamsPlaceHolder('Ej: tu@correo.cl')
				,
				new InputText('name', 'Nombre')
					.setParamsValidation((value: any) => (value !== '' && value.length > 5 && value.length < 10))
				,
				new InputPhone('phone', 'Fono')
					.isParamsOptional()
					.setParamsPlaceHolder('Ej: 9 1234 5678')
				,
				new InputText('pLastName', 'Apellido Paterno'),
				new InputPassword('password', 'Contraseña')
					.setParamsVisible(Component.Actions.INSERT)
				,
				new InputSelect('isValid', '¿Esta Activo?', ['Sí', 'No'])
					.setParamsVisible(Component.Actions.UPDATE)
				,
				new InputText('mLastName', 'Apellido Materno')
					.isParamsOptional()
				,
				new InputButton('company', 'Empresa')
					.setParamsIcon('pen')
					.setParamsReadOnly(Component.Actions.UPDATE)
			]
		}
	]
}