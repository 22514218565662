// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components (.vue)
import PopupTable from '@/Components/Global/PopupTable/template.vue'

// Components (Refs)
import { PopupTableRef } from '@/Components/Global/PopupTable/component'

// Constants
import { Component } from '@/Constants/Global/Component'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Helpers
import { BaseInput }       from '@/Helpers/Components/DataForm/BaseInput'
import { InputButton }     from '@/Helpers/Components/DataForm/InputButton'
import { InputDatePicker } from '@/Helpers/Components/DataForm/InputDatePicker'
import { InputEmail }      from '@/Helpers/Components/DataForm/InputEmail'
import { InputNumber }     from '@/Helpers/Components/DataForm/InputNumber'
import { InputLabel }      from '@/Helpers/Components/DataForm/InputLabel'
import { InputPassword }   from '@/Helpers/Components/DataForm/InputPassword'
import { InputPhone }      from '@/Helpers/Components/DataForm/InputPhone'
import { InputSelect }     from '@/Helpers/Components/DataForm/InputSelect'
import { InputText }       from '@/Helpers/Components/DataForm/InputText'
import { InputTextArea }   from '@/Helpers/Components/DataForm/InputTextArea'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const DataForm = VueMixins(MixinBase, MixinComponent, MixinResponsive).extend({
	name: 'DataForm',

	components: {
		PopupTable
	},

	data: function() {
		return {
			params: undefined as DataFormParamsDefinition,
			tabs: [] as Array<DataFormTabDefinition>,
			states: {
				action: Component.Actions.INSERT,
				showPopupTable: false,
				validateState: true
			}
		}
	},

	computed: {
		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		}
	},

	methods: {
		_checkRequired: function(item: DataFormValidInputTypes) {
			// El Elemento puede ser deshabilitado/habilitado si la propiedad 'enabled' esta presente
			// debido al llamado de la función setEnabled.
			if (item.Params?.enabled) {
				return !item.Params.enabled
			}

			// El Elemento puede ser deshabilitado si la propiedad 'readOnlyAt' esta presente.
			if (item.Params?.readOnlyAt) {
				return this._checkReadOnly(item)
			}

			// El Elemento puede ser deshabilitado si la propiedad 'requires' esta presente.
			else if (item.Params?.requires) {
				const requiredField = item.Params.requires.field

				for (let n = 0; n < this.tabs.length; n++) {
					for (const element of this.tabs[n].columns) {

						if (element.Key === requiredField) {
							// Si la propiedad 'values' esta presente, evaluar su valor,
							// de lo contrario, validar por defecto valores vacios/nulos.
							const values = item.Params.requires?.values
							if (values) {
								return this._validateValues(element, values)
							}
							else {
								if (element instanceof InputText)   return element.Value === ''
								if (element instanceof InputButton) return element.Data.value === ''
								if (element instanceof InputSelect) return element.Selected === null
							}
						}
					}
				}
			}
			return false
		},

		_checkReadOnly: function(item: DataFormValidInputTypes) {
			// El Elemento puede ser deshabilitado si la propiedad 'readOnlyAt' esta presente.
			if (item.Params?.readOnlyAt) {
				return Array.isArray(item.Params.readOnlyAt)
					? item.Params.readOnlyAt.includes(this.states.action)
					: item.Params.readOnlyAt === this.states.action
			}
			return false
		},

		_getColumn: function(index: number) {
			const currentTab = this._getCurrentObjectTab()
			if (currentTab) {
				if (currentTab?.columns) {
					// Los elementos deben ser filtrados antes de decidir a cual columna corresponden.
					const auxItems = currentTab.columns.filter((item) => {
						if (item?.Params && (item.Params?.visible !== undefined)) {
							if (item.Params.visible === this.states.action) return true
						}
						else return true
					})

					// El ultimo filtro corresponde al 'index' de la columna.
					const items: Array<DataFormValidInputTypes> = []
					for (let step = 0; step < auxItems.length; step++) {
						if (step % this.params.numColumns === index) {
							const item = auxItems[step]
							items.push(item)
						}
					}

					// Retornar el Array con los items.
					return items
				}
			}
			return []
		},

		_getCurrentObjectTab: function() {
			if (this.tabs.length > 0) {
				for (let tab of this.tabs) {
					if (this.getCurrentTab() === tab.header.key) {
						return tab
					}
				}
			}
			return undefined
		},

		_getDisabledClass: function(state: boolean) {
			return state ? 'disabled' : ''
		},

		_getHeaderButtonClass: function(button: DataFormButtonDefinition) {
			let cls = ''
			if (this.getCurrentTab() === button.key) cls += 'button-active '
			if (button?.isFirst === true) cls += 'button-is-first '
			if (button?.isLast === true) cls += 'button-is-last '
			return cls.trim()
		},

		_getHeaderButtonStyle: function() {
			const { length } = this._getHeaderButtons()
			return `width: calc(100% / ${ length });`
		},

		_getHeaderButtonText: function(button: DataFormButtonDefinition) {
			switch (this.states.action) {
				case Component.Actions.INSERT: return button.text.onInsert
				case Component.Actions.READ  : return button.text.onSelect
				case Component.Actions.UPDATE: return button.text.onUpdate
			}
		},

		_getHeaderButtons: function() {
			if (this.tabs.length > 0) {
				const buttons: Array<DataFormButtonDefinition> = []
				for (let tab of this.tabs) {
					buttons.push(tab.header)
				}
				return buttons
			}
			return []
		},

		_getInputButtonClass: function(item: DataFormValidInputTypes) {
			let classString = !this._checkRequired(item) ? 'btn-green' : 'btn-gray'
			if (item.Params?.button) {
				if (item.Params.button.content === 'icon') {
					classString += ' has-icon'
				}
				else if (item.Params.button.content === 'text') {
					classString += ' has-text'
				}
			}
			return classString
		},

		_getInputFormatter: function(item: DataFormValidInputTypes) {
			return item.Params?.formatter ? item.Params.formatter : undefined
		},

		_getInputPasswordVisibilityIcon: function(item: InputPassword) {
			return item.Visibility ? 'eye' : 'eye-slash'
		},

		_getInputState: function(item: DataFormValidInputTypes, stateFunctionName = 'state') {
			if (item?.Params && item.Params?.[stateFunctionName]) {
				return item.Params[stateFunctionName](item)
			}
			return null
		},

		_getInputType: function(item: DataFormValidInputTypes) {
			switch (item.Params.type) {
				case 'phone': {
					return 'text'
				}
				default: {
					if (item instanceof InputPassword && item.Visibility) return 'text'
					return item.Params.type
				}
			}
		},

		_getRows: function() {
			const tab = this._getCurrentObjectTab()
			if (tab !== undefined) {
				if (tab?.rows) {
					// Segun el indice de la columna, insertar los items correspondientes.
					const items = []
					for (let i = 0; i < tab.rows.length; i++) {
						const item = tab.rows[i]
						if (item !== undefined) {
							if (item?.Params && (item.Params?.visible !== undefined)) {
								if (item.Params.visible === this.states.action) {
									items.push(item)
								}
							}
							else items.push(item)
						}
					}
					return items
				}
			}
			return []
		},

		_getPlaceHolder: function(item: DataFormValidInputTypes, state = false) {
			if (state) {
				if (item.Params?.requires && item.Params.requires?.friendly) {
					return `Debe seleccionar el campo '${ item.Params.requires.friendly }' primero...`
				}
			}
			return item.Params.placeholder || ''
		},

		_getSelectFirstOption: function(item: DataFormValidInputTypes) {
			if (item.Params?.placeholder) {
				return item.Params.placeholder
			}
			return '- Seleccione una Opción -'
		},

		_isOptional: function(item: DataFormValidInputTypes) {
			if (item.Params?.optional) {
				return item.Params.optional
			}
			return false
		},

		_resolveEventHandlers: function(item: BaseInput, nativeEvent: any) {
			if (item?.Events) {
				return item.Events?.[nativeEvent.type] ? item.Events.keyup(item, nativeEvent) : null
			}
			return null
		},

		_validateValues: function(item: DataFormValidInputTypes, values: Array<any>) {
			// 'values' puede ser de tipo String o Array.
			if (Array.isArray(values)) {
				if (item instanceof InputButton) return !(values.includes(item.Data.value))
				if (item instanceof BaseInput)   return !(values.includes(item.Value))
				if (item instanceof InputSelect) return !(values.includes(item.Selected))
			}
			else if (typeof values === 'string') {
				if (item instanceof InputButton) return !(item.Data.value === values)
				if (item instanceof BaseInput)   return !(item.Value === values)
				if (item instanceof InputSelect) return !(item.Selected === values)
			}
			return undefined
		},

		addOption: function(key: string, option: object) {
			const element = this.getElement(key)
			if (element !== undefined && element instanceof InputSelect) {
				element.Options.push(option)
			}
		},

		clearInputs: function() {
			for (const tab of this.tabs) {
				for (const tabKey of ['columns', 'rows']) {
					if (tab?.[tabKey] !== undefined) {
						for (const element of tab[tabKey] as Array<DataFormValidInputTypes>) {
							if (element instanceof InputButton)     element.Data.value = ''
							if (element instanceof BaseInput)       element.Value    = ''
							if (element instanceof InputDatePicker) element.Value    = ''
							if (element instanceof InputSelect)     element.Selected = null
						}
					}
				}
			}
		},

		clearOptions: function(key: string) {
			const element = this.getElement(key)
			if (element !== undefined && element instanceof InputSelect) {
				element.Options = []
			}
		},

		getCurrentTab: function() {
			return this.params.currentTab
		},

		getElement: function<T>(key: string) {
			const { currentTab } = this.params
			for (const tab of this.tabs) {
				if (tab.header.key === currentTab) {
					for (const tabKey of ['columns', 'rows']) {
						if (tab?.[tabKey] !== undefined) {
							for (const element of tab[tabKey] as Array<DataFormValidInputTypes>) {
								if (element.Key.toLowerCase() === key.toLowerCase()) {
									return element as T
								}
							}
						}
					}
					break
				}
			}
		},

		getHeaderElement: function(key: string) {
			const { currentTab } = this.params
			for (const tab of this.tabs) {
				if (tab.header.key === currentTab) {
					if (tab.header !== undefined && tab.header.key === key) return tab.header
				}
			}
		},

		getNumColumns: function() {
			return this.params?.numColumns || 0
		},

		getValue: function(key: string, dataProperty = '') {
			const element = this.getElement(key)
			if (element !== undefined) {
				if (element instanceof InputButton)     return (element.Data as any)[dataProperty]?.trim()
				if (element instanceof BaseInput)       return (typeof element.Value === 'number') ? element.Value : element.Value?.trim()
				if (element instanceof InputDatePicker) return element.Value
				if (element instanceof InputSelect)     return element.Selected?.trim()
			}
		},

		initialize: function(dataForm: { params: any, tabs: any }) {
			this.params = dataForm.params
			this.tabs = dataForm.tabs
		},

		initPopupTable: function(fields: Array<any>, items: Array<any>) {
			const { _dataTable } = this._popupTable
			_dataTable.setElements(fields, items)
		},

		setEnabled: function(key: string, state: boolean) {
			const element = this.getElement(key)
			if (element !== undefined && element instanceof InputButton) {
				element.Params.enabled = state
			}
		},
	
		setValue: function(key: string, value: any, dataProperty = '') {
			const element = this.getElement(key)
			if (element !== undefined) {
				if (element instanceof InputButton)     (element.Data as any)[dataProperty] = value
				if (element instanceof BaseInput)       element.Value    = value
				if (element instanceof InputDatePicker) element.Value    = value
				if (element instanceof InputSelect)     element.Selected = value
			}
		},

		validateStates: function() {
			for (let col = 0; col < this.getNumColumns(); col++) {
				for (const $item of this._getColumn(col)) {
					if ($item?.Params && $item.Params?.state) {
						if ($item.Params.state($item) === false) {
							return false
						}
					}
				}
			}
			return true
		},

		onDFCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			DevelopmentTools.printWarn('[DataForm]:onDFCurrentSearchPaginationChanged event triggered')
			this.$emit('onDFCurrentSearchPaginationChanged', page, searchParams)
		},

		onDFPaginationChanged: function(page: number) {
			DevelopmentTools.printWarn('[DataForm]:onDFPaginationChanged event triggered')
			this.$emit('onDFPaginationChanged', page)
		},

		onDFRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[DataForm]:onDFRefreshButtonClick event triggered')
			this.$emit('onDFRefreshButtonClick')
		},

		onDFSearchButtonClicked: function(searchParams: any) {
			DevelopmentTools.printWarn('[DataForm]:onDFSearchButtonClicked event triggered')
			this.$emit('onDFSearchButtonClicked', searchParams)
		},

		onInputButtonClick: function(key: string, event: Event) {
			DevelopmentTools.printWarn('[DataForm]:onInputButtonClick event triggered')
			this.$emit('onInputButtonClick', key, event)
		},

		onOptionChange: function(key: string, event: Event) {
			DevelopmentTools.printWarn('[DataForm]:onOptionChange event triggered')
			this.$emit('onOptionChange', key, event)
		},

		onPTClose: function() {
			DevelopmentTools.printWarn('[DataForm]:onPTClose event triggered')
			this.setStates<DataFormRef['states']>({ showPopupTable: false })
		},

		onPTSelect: function(currentRow: object) {
			DevelopmentTools.printWarn('[DataForm]:onPTSelect event triggered')
			if (currentRow !== undefined) {
				this.setStates<DataFormRef['states']>({ showPopupTable: false })
				this.$emit('onPTSelect', currentRow)
			}
		},

		onRowDoubleClick: function(clickedRow: object) {
			DevelopmentTools.printWarn('[DataForm]:onRowDoubleClick():Event triggered')
			this.setStates<DataFormRef['states']>({ showPopupTable: false })
			this.$emit('onRowDoubleClick', clickedRow)
		},

		onSetCurrentTab: function(tabName: string) {
			if (this.getCurrentTab() !== tabName) {
				this.params.currentTab = tabName
			}
		}
	}
})

// Exports
export default DataForm
export type DataFormRef = InstanceType<typeof DataForm>

// Interface Exports
interface DataFormButtonDefinition {
	key: string
	isFirst?: boolean
	isLast?: boolean
	text: {
		onInsert?: string
		onSelect?: string
		onUpdate?: string
	}
}

export interface DataFormParamsDefinition {
	currentTab: string
	numColumns: number
}

export interface DataFormTabDefinition {
	[key: string]: DataFormTabHeaderDefinition | Array<DataFormValidInputTypes>
	header: DataFormTabHeaderDefinition
	columns?: Array<DataFormValidInputTypes>
	rows?: Array<DataFormValidInputTypes>
}

interface DataFormTabHeaderDefinition {
	key: string
	text: DataFormButtonDefinition['text']
}

// Type Exports
export type DataFormValidInputTypes = (
	InputDatePicker |
	InputButton       |
	InputEmail        |
	InputLabel        |
	InputNumber       |
	InputPassword     |
	InputPhone        |
	InputSelect       |
	InputText         |
	InputTextArea
)