// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputPhone extends BaseInput {
	constructor(key: string, label: string) {
		super()
		this.Key   = key
		this.Label = label
		this.setParamsFormatter(this.resolveFormatter)
		this.setParamsType('phone')
		this.setParamsState(this.resolveInputState)
	}

	public get Value() {
		return super.Value !== '' ? `+56 ${ super.Value }` : ''
	}

	public set Value(value) {
		super.Value = value
	}

	public resolveFormatter(value: string) {
		value = value.replace(/[^0-9]/g, '').trim()
		return value.replace(/(\d{1})(\d{4})(\d{4})/, "$1 $2 $3")
	}

	public resolveInputState(self: InputPhone) {
		let aux = self.Value.trim()
		if (aux === '') return null
		return /^[2|9][\s][0-9]{4}[\s][0-9]{4}$/i.test(aux)
	}
}