// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputText extends BaseInput {
	constructor(key: string, label: string) {
		super()
		this.Key   = key
		this.Label = label
		this.setParamsState(this.resolveInputState)
		this.setParamsType('text')

	}

	public resolveInputState(self: any) {
		let aux = self.value.trim()
		if (aux === '') return null
		if (aux.length < 3 || aux.length > 120) return false
		return true
	}
}